<template>
  <b-form-group
    :id="groupId"
    :label="label"
    :label-for="id"
    ref="formGroup"
    label-class="form-label"
    :description="description"
    :invalid-feedback="invalidFeedback"
    :state="state"
    :class="[
      localValue
        ? localValue.toString().length > 0
          ? 'is-filled is-focus'
          : ''
        : '',
      isFocused ? 'is-focus' : '',
      whiteBG ? 'white-label-bg' : '',
      isDisabled ? 'is-disabled' : '',
    ]"
  >
    <b-input-group>
      <b-input-group-prepend v-if="isPrepend" ref="inputPrepend">
        <slot name="prepend"></slot>
      </b-input-group-prepend>
      <b-form-textarea
        v-if="isTextArea"
        :id="id"
        :type="type"
        :required="isRequired"
        :placeholder="isFocused ? placeholder : ''"
        :state="state"
        rows="3"
        max-rows="6"
        :ref="id"
        :trim="trim"
        :form="form"
        :aria-autocomplete="autocomplete"
        :disabled="isDisabled"
        :value="value"
        @input="(e) => updateInput(e)"
        @blur="(e) => localChangeFn(e)"
        @focus="(e) => onFocus(e)"
      ></b-form-textarea>
      <b-form-input
        v-else
        :id="id"
        :type="type"
        :required="isRequired"
        :placeholder="isFocused ? placeholder : ''"
        :state="state"
        :ref="id"
        :aria-autocomplete="autocomplete"
        :trim="trim"
        :form="form"
        :disabled="isDisabled"
        :value="value"
        @input="(e) => updateInput(e)"
        @blur="(e) => localChangeFn(e)"
        @focus="(e) => onFocus(e)"
      ></b-form-input>
      <b-input-group-append v-if="isAppend" ref="inputAppend">
        <slot name="append"></slot>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
/* eslint-disable no-console */
export default {
  name: "FormInputGroup",
  data() {
    return {
      localValue: "",
      isFocused: false,
      prependElementWidth: 0,
    };
  },
  props: {
    id: String,
    groupId: String,
    type: String,
    label: String,
    description: {
      default: "",
      type: String,
    },
    autocomplete: {
      type: Boolean,
      default: true,
    },
    isRequired: Boolean,
    trim: {
      default: false,
      type: Boolean,
    },
    isAppend: {
      default: false,
      type: Boolean,
    },
    isPrepend: {
      default: false,
      type: Boolean,
    },
    placeholder: String,
    invalidFeedback: {
      default: "",
      type: String,
    },
    isTextArea: {
      default: false,
      type: Boolean,
    },
    isDisabled: {
      default: false,
      type: Boolean,
    },
    state: {
      default: null,
      type: Boolean,
    },
    form: {
      default: "",
      type: String,
    },
    whiteBG: {
      default: true,
      type: Boolean,
    },
    value: {
      default: null,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.localValue = val;
      },
    },
  },
  mounted() {
    this.localValue = this.value;
    this.offsetLabelPosition();
  },
  methods: {
    updateInput(val) {
      this.localValue = val;
      this.$emit("input", val);
    },
    localChangeFn(val) {
      this.isFocused = false;
      this.$emit("blurLocal", val.target.value);
    },
    onFocus() {
      this.isFocused = true;
    },
    offsetLabelPosition() {
      if (this.isPrepend) {
        this.prependElementWidth = this.$refs.inputPrepend
          ? this.$refs.inputPrepend.offsetWidth
          : 70;
        this.prependElementWidth =
          this.prependElementWidth < 50 ? 50 : this.prependElementWidth;
        // console.log(this.prependElementWidth);
        this.$refs.formGroup.$el.children[0].setAttribute(
          "style",
          `left: ${this.prependElementWidth + 10}px`
        );
      }
    },
  },
};
</script>
<!-- "unscoped" attribute for global CSS -->
<style scoped>
/* == FORM GROUP STYLING == */
/* customized form control */
.form-group {
  position: relative;
}
/* placeholder text color */
.form-group >>> input.form-control::placeholder,
.form-group >>> textarea.form-control::placeholder {
  color: #8793A3;
}
/* == FORM INPUT STYLING == */
/* form input control styling (default state) */
.form-group >>> input.form-control,
.form-group >>> textarea.form-control {
  background-color: transparent;
  z-index: 2;
  border-radius: 5;
  min-height: 50px;
  /* min-height: 25px; */
  padding: 0.175rem 0.75rem;
  border-color: #8793A3;
  /* type */
  -moz-appearance: textfield;
}
.form-group >>> textarea.form-control {
  overflow-y: auto !important;
}
/* form input control error state styling (valid state) */
.form-group.is-valid >>> input.form-control,
.form-group.is-valid >>> textarea.form-control {
  border-color: var(--success);
}
/* form input control error state styling (invalid state) */
.form-group.is-invalid >>> input.form-control,
.form-group.is-invalid >>> textarea.form-control {
  border-color: var(--danger);
}
/* form input control focused styling (focus state) */
.form-group.is-focus >>> input.form-control,
.form-group.is-focus >>> textarea.form-control {
  z-index: 0;
}
.form-group >>> input.form-control:focus,
.form-group >>> textarea.form-control:focus {
  box-shadow: none;
  border-color: #0B9B7D;
}
/* form input control focused error state styling (focus/valid state) */
.form-group.is-focus.is-valid >>> input.form-control,
.form-group.is-focus.is-valid >>> textarea.form-control {
  border-color: var(--success);
}
/* form input control focused error state styling (focus/invalid state) */
.form-group.is-focus.is-invalid >>> input.form-control,
.form-group.is-focus.is-invalid >>> textarea.form-control {
  border-color: var(--danger);
}
/* form input control filled styling (filled state) */
.form-group.is-filled >>> input.form-control,
.form-group.is-filled >>> textarea.form-control {
  border-color: #0B9B7D;
}
/* form input control filled error state styling (filled/valid state) */
.form-group.is-filled.is-valid >>> input.form-control,
.form-group.is-filled.is-valid >>> textarea.form-control {
  border-color: var(--success);
}
/* form input control filled error state styling (filled/invalid state) */
.form-group.is-filled.is-invalid >>> input.form-control,
.form-group.is-filled.is-invalid >>> textarea.form-control {
  border-color: var(--danger);
}
/* form input control disabled state styling (disabled state) */
.form-group.is-disabled >>> input.form-control,
.form-group.is-disabled >>> textarea.form-control,
.form-group:disabled >>> input.form-control,
.form-group:disabled >>> textarea.form-control {
  color: var(--ghost-white);
}
/* == APPEND/PREPEND STYLING == */
/* form append/prepend icon container (default state) */
.form-group >>> .input-group-append,
.form-group >>> .input-group-append {
  border: 1px solid #8793A3;
  border-left: 0px;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out;
}
/* form append/prepend icon container (focus/filled state) */
.form-group.is-focus >>> .input-group-append,
.form-group.is-focus >>> .input-group-prepend,
.form-group.is-filled >>> .input-group-append,
.form-group.is-filled >>> .input-group-prepend {
  box-shadow: none;
  border-color: #0B9B7D;
}
.form-group >>> .input-group-append:focus,
.form-group >>> .input-group-prepend:focus {
  box-shadow: none;
  border-color: #0B9B7D;
}
/* form append/prepend icon container error state styling (valid state) */
.form-group.is-valid >>> .input-group-append,
.form-group.is-valid >>> .input-group-prepend {
  border-color: var(--success);
}
/* form append/prepend icon container error state styling (invalid state) */
.form-group.is-invalid >>> .input-group-append,
.form-group.is-invalid >>> .input-group-prepend {
  border-color: var(--danger);
}
/* form append/prepend icon container filled error state styling (filled/valid state) */
.form-group.is-filled.is-valid >>> .input-group-append,
.form-group.is-filled.is-valid >>> .input-group-prepend {
  border-color: var(--success);
}
/* form append/prepend icon container filled error state styling (filled/invalid state) */
.form-group.is-filled.is-invalid >>> .input-group-append,
.form-group.is-filled.is-invalid >>> .input-group-prepend {
  border-color: var(--danger);
}
/* form append/prepend inner button focused state styling (focus state) */
.form-group >>> .input-group-append > button:focus,
.form-group >>> .input-group-prepend > button:focus {
  box-shadow: none !important;
  border-color: #0B9B7D;
}
/* == LABEL STYLING == */
/* form label (default state) */
.form-group >>> label.form-label {
  position: absolute;
  color: #8793A3;
  font-size: 1rem;
  width: auto;
  top: 12px;
  left: 13px;
  z-index: 1;
  cursor: text;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  white-space: nowrap;
  transition: top 100ms linear 0s, left 100ms linear 0s, color 100ms linear 0s,
    font-size 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
/* form label filled styling (filled state) */
.form-group.is-filled >>> label.form-label {
  opacity: 0;
}
/* form label focused styling (focus state) */
.form-group.is-focus >>> label.form-label {
  font-size: 0.8rem;
  color: #0B9B7D;
  top: -9px;
  left: 10px;
  opacity: 1;
  z-index: 2;
}
/* form label focused error state styling (focus/valid state) */
.form-group.is-focus.is-valid >>> label.form-label {
  color: var(--success);
}
/* form label focused error state styling (focus/invalid state) */
.form-group.is-focus.is-invalid >>> label.form-label {
  color: var(--danger);
}
/* form label disabled state styling (disabled state) */
.form-group:disabled >>> label,
.form-group.is-disabled >>> label {
  color: var(--ghost-white);
}
/* form label background (default state) */
.form-group >>> label:before {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--white);
  z-index: -1;
}
/* form label background focused styling (focus state) */
.form-group.is-focus >>> label::before {
  width: 100%;
  height: 100%;
}
/* form label background disabled focused styling (disabled/focus state) */
.form-group:disabled.is-focus >>> label::before,
.form-group.is-disabled.is-focus >>> label::before {
  background-color: var(--white);
  height: 3px;
  top: 8px;
}
/* white label bg */
.form-group.white-label-bg >>> label:before {
  background-color: var(--white);
}
/* == CUSTOM BROWSER SPECIFIC STYLING == */
/* input type number fix */
/* Chrome, Safari, Edge, Opera */
.form-group >>> input.form-control::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.form-group >>> input.form-control[type="number"] {
  -moz-appearance: textfield;
}
</style>
